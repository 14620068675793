<template>
  <div>
    <div class="flix-well flix-text-center" v-if="sendet">
      <div class="flix-html-h1 flix-text-primary">
        <flixIcon :id="'ok'" />
      </div>
      {{ sendet }} {{ $tc('message.message', sendet) }}
    </div>
    <flixExpand :settings="{bottomButton: false, buttonClass: 'flix-btn flix-btn-primary'}" v-if="!sendet">
      <template v-slot:button><flixIcon :id="'plus-sign'" /> {{ $t("message.add", {name: $tc('message.message', 1)}) }}</template>
      <template v-slot:expand>
        <select class="flix-form-control" v-model="select.calendar">
          <option value="">{{ $t("message.goTo", {name: $tc("message.calendar", 1) }) }}</option>
          <option v-for="(calendar, index) in $store.getters.assistents" :key="index" v-bind:value="calendar.ID">{{ calendar.title }} || #{{ calendar.ID }}</option>
        </select>
        <transition name="flixFadeIn">
          <div v-if="bookings && select.calendar" :key="select.calendar">
            <div class="flix-form-group">
              <ul class="flix-nav flix-nav-tabs">
                <li class="flix-html-li" :class="{'flix-active': select.booking.indexOf(booking.ID) === -1}" v-for="(booking, index) in bookings" :key="index">
                  <a class="flix-html-a" href="#" @click.prevent="getSelectBookings(booking.ID)">
                    <div v-html="booking.booking_date"></div>
                    <div class="flix-html-small" v-html="booking.email"></div>
                    <div class="flix-html-small" v-html="'#'+booking.booking_id"></div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="flix-form-group">
              <b>{{ select.booking.length }}/{{ bookings.length }}</b>&nbsp;
              <a href="#" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="getSelectBookings('all')"><flixIcon :id="'check'" /><flixIcon :id="'check'" /><flixIcon :id="'check'" /></a>&nbsp;
              <a href="#" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="getSelectBookings('none')"><flixIcon :id="'unchecked'" /><flixIcon :id="'unchecked'" /><flixIcon :id="'unchecked'" /></a>
            </div>
            <transition name="flixFadeIn">
              <div class="flix-form-group" v-if="select.booking.length" :key="select.booking.length">
                <textarea v-model="message" class="flix-form-control" :placeholder="$tc('message.message', 1)"></textarea>
                <saveBtn :settings="{block: true}" :onClick="function(){ saveMessages() }">
                  <template v-slot:text>
                    {{ $t('message.save', { name: $tc('message.message', 1) }) }}
                  </template>
                </saveBtn>
              </div>
            </transition>
          </div>
        </transition>
      </template>
    </flixExpand>

  </div>
</template>
<script>
import md5 from 'js-md5'

export default {
  components: {},
  props: { callback: Function },
  data () {
    return {
      sendet: 0,
      calendars: false,
      bookings: false,
      message: '',
      select: {
        calendar: '',
        booking: []
      }
    }
  },
  watch: {
    'select.calendar' (val) { this.select.booking = []; if (val === '') { this.bookings = []; return false } this.getBookings() }
  },
  methods: {
    getBookings () {
      this.bookings = this.$store.getters.bookings[this.select.calendar]
    },
    getSelectBookings (add) {
      if (add === 'all') {
        this.select.booking = []
        this.bookings.forEach(function (booking) {
          this.select.booking.push(booking.ID)
        }.bind(this))
        return false
      }
      if (add === 'none') {
        this.select.booking = []
        return false
      }
      if (this.select.booking.indexOf(add) !== -1) {
        for (var key in this.select.booking) {
          if (this.select.booking[key] === add) {
            this.select.booking.splice(key, 1)
          }
        }
      } else {
        this.select.booking.push(add)
      }
    },
    saveMessages () {
      if (this.message.trim().length === 0) {
        return false
      }
      this.select.booking.forEach(function (id) {
        this.insertAnswer(id)

        if (id === Object.values(this.select.booking)[Object.keys(this.select.booking).length - 1]) {
          setTimeout(function () {
            this.sendet = 0
            this.select = {
              calendar: '',
              booking: []
            }
            this.updateStore()
          }.bind(this), 5000)
        }
      }.bind(this))
    },
    insertAnswer (id) {
      var send = {
        user: this.$store.getters.user.md5_id,
        data: {
          chat_nr: this.$store.getters.user.md5_id + '/' + this.select.calendar + '/' + md5(id + ''),
          msg: this.$nl2br(this.message),
          user: 'bot',
          view: JSON.stringify(['bot'])
        }
      }

      this.$flix_post({
        url: 'userchat/set_msg',
        data: send,
        callback: function (ret) {
          this.sendet = this.sendet + 1
        }.bind(this)
      })
    },
    updateStore () {
      this.$store.commit('cleanMessages')
      this.$store.dispatch('getMessages', function () {})
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
